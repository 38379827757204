import * as React from "react";

import {
  MultiSelect,
  MultiSelectChangeEvent,
} from "@progress/kendo-react-dropdowns";
import { GridFilterCellProps } from "@progress/kendo-react-grid";
import { Button } from "@progress/kendo-react-buttons";

interface MultiSelectFilterCellProps extends GridFilterCellProps {
  data: string[];
  operatorFunc : Function
}

// this will be a filter for the roles.
export const MultiSelectFilterCell = (props: MultiSelectFilterCellProps) => {
  let hasValue: any = (value: any) => Boolean(value);

  const onChange = (event: MultiSelectChangeEvent) => {
    hasValue = hasValue(event.target.value);
    props.onChange({
      value: hasValue ? event.target.value : [],      
      operator: props.operatorFunc,       
      syntheticEvent: event.syntheticEvent,
    });
  };

  const onClearButtonClick = (event:any) => {    
    event.preventDefault();
    props.onChange({
      value: [],
      operator: props.operatorFunc,
      syntheticEvent: event
    });
  };
  return (
    <div className="k-filtercell">
      <MultiSelect
        data={props.data}
        onChange={onChange}
        value={props.value || []}
      />
      <Button
        title="Clear"
        disabled={!hasValue(props.value)}
        onClick={onClearButtonClick}
        icon="filter-clear"
      />
    </div>
  );
};