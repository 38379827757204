import {useState} from 'react';
// thunk imports
import { useAppSelector, useAppDispatch } from '../../app/hooks';
import { loginUserAsync } from '../../app/authentication/authenticationSlice';
import {resetPasswordAsync} from '../../app/userManagement/userManagementSlice';
import { ILogin } from '../../app/interfaces/commonInterfaces';

import { Input } from "@progress/kendo-react-inputs";

export function LoginComponent() {  
  const [login, setLogin] = useState<ILogin>({userName: "", password: ""});
  const [resetPassword, setResetPassword] = useState<boolean>(false);
  const [hasResetPassword, setHasResetPassword] = useState<boolean>(false);
  
  const authStatus = useAppSelector(state => state.authInfo.status);  
  const adminEmail = useAppSelector(state => state.config.config?.adminEmail);
  
  //const config = useAppSelector(selectConfig)
  const dispatch = useAppDispatch()

  const handleInputChange = (event:any) => {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;

    setLogin({...login, [name]: value});
  }

  const handleReset = () => {
    setResetPassword(!resetPassword);
  }

  const handleSubmitReset = () => {
    dispatch(resetPasswordAsync(login))
    .then(()=> {
      setHasResetPassword(true);
      setResetPassword(false);
    });  
  }

  const handleSubmit = () => {
    dispatch(loginUserAsync(login));  
  }
  
  return (  
    <div className='module-login'>
       <h1>Welcome to the NCR GIS Data Repository</h1> 
       <div>
          {!resetPassword && 
            <fieldset className={"k-form-fieldset"}>
              <legend className={"k-form-legend"}>
                Please LOG IN to the repository to access the data.
              </legend>
              
              <div className="mb-3">
                <label>Login</label>
                <Input validityStyles={false} name="userName" type="text" style={{ width: "100%" }}
                  required={true} onChange={handleInputChange} />
              </div>

              <div className="mb-3">
              <label>Password</label>
                <Input validityStyles={false} name="password" type="password" style={{ width: "100%" }}
                  required={true} onChange={handleInputChange} />
              </div>
            
              {authStatus=== "denied" && <div><label >Invalid login or password</label></div>}
              {hasResetPassword && <div><label >If your user name is valid, your password has been reset.  Please check your email for the new password.</label></div>}              

              <div className="k-form-buttons has-plain-anchor">
                <button type={"button"} onClick={handleSubmit} className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-base">Login</button>
                <div className="plain-anchor-holder">                
                  <a className='plain-anchor' onClick={handleReset} >Request new password</a>                
                  <a className='plain-anchor' href={"mailto:" +  adminEmail} >Contact administrator</a>
                </div>
              </div>          
              
            </fieldset>
          }
          {resetPassword && 
            <fieldset className={"k-form-fieldset"}>
              <legend className={"k-form-legend"}>
                Please enter your Login to recieve an email with your new password.
              </legend>
              
              <div className="mb-3">
                <Input validityStyles={false} name="userName" type="text" style={{ width: "100%" }}
                  label="Login" required={true} onChange={handleInputChange} />
              </div>

              <div className="k-form-buttons">
                <button type={"button"} onClick={handleSubmitReset}
                  className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-base" 
                  >Submit</button>
                  <button type={"button"} onClick={handleReset}
                    className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-base cancel-btn" 
                    >Cancel</button>
              </div>          
            </fieldset>
          }


        </div>
    </div>

  );
}
