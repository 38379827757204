import React from "react";

// thunk imports
import { useAppSelector, useAppDispatch } from '../../app/hooks';
import { closeNavigationDialog } from '../../app/navigation/navigationSlice';
import { updatePasswordAsync } from '../../app/userManagement/userManagementSlice';

import { ValidatedInput} from '../commonControls/validationControls/ValidationControlComponent'
import { Form, Field, FormElement, FormRenderProps } from "@progress/kendo-react-form";
import { InputChangeEvent } from "@progress/kendo-react-inputs";
import { Dialog } from "@progress/kendo-react-dialogs";
import { ProgressBar } from "@progress/kendo-react-progressbars";
import { Error } from "@progress/kendo-react-labels";

import { IUserDetail, IUpdatePassword, IBaseResult } from "../../app/interfaces/commonInterfaces";
import {addSuccessNotification, addErrorNotification} from "../../app/notification/notificationSlice";

export const ChangeUserPasswordComponent = () => {
  const dispatch = useAppDispatch();
  const editedUser: IUserDetail = useAppSelector(state => state.navigation.dialogState.data);
  const isAdmin = useAppSelector(state => state.authInfo.userLoginInfo?.isAdmin);

  const [passwordStrength, setPasswordStrength] = React.useState(0);
  const [customText, setCustomText] = React.useState("Password Strength");
  const [errorMessage, setErrorMessage] = React.useState<string>("");
  
  const [updatePassword, setUpdatePassword] = React.useState<IUpdatePassword>({
    userName: editedUser.userName!,
    password: "",
    password2: "",
    oldPassword: ""
  });

  const [emptyStyles, setEmptyStyles] = React.useState({
    color: "",
    background: "",
  });

  const [progressStyles, setProgressStyles] = React.useState({
    background: "",
  });
  const max = 5;

  const handleInputChange = (e: InputChangeEvent) => {
    
    const target = e.target;
    
    let value = target.value as string;
    const name = target.name!;

    setUpdatePassword({...updatePassword, [name]: value});

    if (name === "password"){
      validatePassword(value);
    }
  };

  const regExpStrength = (password: string) => {
    var hasNumber = new RegExp(/[0-9]/);
    var hasUpperChar = new RegExp(/[A-Z]/);
    var hasLowerChar = new RegExp(/[a-z]/);
    var hasSymbols = new RegExp(/[!@#$%^&*()_+=\[{\]};:<>|./?,-]/);
    
    let strength = 0;
    if (hasNumber.test(password))strength++;
    if (hasUpperChar.test(password))strength++;
    if (hasLowerChar.test(password))strength++;
    if (hasSymbols.test(password)) strength++;
    return strength;
  }

  const validatePassword = (password: string) => {
    let strength = regExpStrength(password);
    if (password.length >= 8) strength++;
    
    setPasswordStrength(strength);

    switch (strength) {
      case 0:
        updateAppearance("Password Strength", "", "", "");
        break;
      case 1:
        updateAppearance("Weak", "red", "#ee9f05", "#ff000014");
        break;
      case 4:
        updateAppearance("Good", "#428bca", "#428bca", "#428bca1f");
        break;
      case 5:
        updateAppearance("Strong", "#8EBC00", "#8EBC00", "#8ebc0021");
        break;
      default:
    }
  }

  const updateAppearance = (
    text: string,
    textColor: string,
    progressBackground: string,
    emptyBackground: string
  ) => {
    setCustomText(text);
    setEmptyStyles({ color: textColor, background: emptyBackground });
    setProgressStyles({ background: progressBackground });
  };

  const closeDialog = () => {
    dispatch(closeNavigationDialog());
  };

  const handleSubmit = (dataItem: { [name: string]: any }) => {
    if (isValid() && updatePassword.password === updatePassword.password2){
      dispatch(updatePasswordAsync(updatePassword)).then(result=>{
        const r = (result.payload as IBaseResult);
        if (r !== undefined && r.isSuccessful){
          dispatch(addSuccessNotification("The password has been successfully updated"));
          closeDialog();
        }
        else{
          console.log(r.message);
          dispatch(addErrorNotification("There was an error updating the password: " + r.message));
          setErrorMessage(r.message);
        }
      });
    }  
    else {
      dispatch(addErrorNotification("Invalid Password"));
    }
  }

  const isValid = ()=>{
    let strength = regExpStrength(updatePassword.password);
    return strength >=3 
      && updatePassword.password.length >=8
      && updatePassword.password.length <= 128
      && updatePassword.password.toLowerCase().indexOf(updatePassword.userName.toLowerCase()) === -1;
      // && updatePassword.password.length < 2;
  }

  const requiredValidator = (value: string) => { 
    const r = (value !== "" && value !== undefined) ? "" : "Please enter a value";
    if (r !== "") 
    return r;
  };

  const passwordFormValidator = (values: any) => { 
    if (updatePassword.password !== updatePassword.password2){
      return {
        VALIDATION_SUMMARY: "",
        ["password2"]: "The passwords don't match.",
      };
    }
  };

  const requiredPasswordValidator = (value: string) => {     
    if (isValid()){
      return "";
    }
    else{
      return "the password is not valid.";
    }
  };

  return ( 
    <div>
      <Dialog title={`Change Password`} onClose={closeDialog}>      
      <Form 
      initialValues={updatePassword}
      onSubmit={handleSubmit}      
      validator={passwordFormValidator}
      render={(formRenderProps: FormRenderProps) => (        
        <FormElement style={{ maxWidth: 650 }}>
          <fieldset className={"k-form-fieldset initial-fieldset"}>
            {/* <legend className={"k-form-legend"}>User Details</legend> */}
            { !isAdmin && (
              <div className="mb-3">         
                <Field type={"password"} name={"oldPassword"} component={ValidatedInput}
                  label={"Old Password"} validator={requiredValidator} onChange={handleInputChange}
                />
              </div>
            )}
            <div className="mb-3">           
                <Field type={"password"} name={"password"} component={ValidatedInput}
                  label={"Password"} validator={requiredPasswordValidator} onChange={handleInputChange}
                 />    
            </div>  
            <div className="mb-3">
              <ProgressBar
                value={passwordStrength}
                max={max}
                progressStyle={progressStyles}
                emptyStyle={emptyStyles}
                label={() => {
                  return <span>{customText}</span>;
                }}
              />
            </div>
            <div className="mb-3">            
                <Field type={"password"} name={"password2"} component={ValidatedInput}
                  label={"Confirm New Password"} onChange={handleInputChange} validator={requiredValidator}
                 />
            </div>              
            <div className="mb-3">              
              {(errorMessage !== "") && <Error>{errorMessage}</Error>} 
            </div>
            <div className="k-form-buttons">
              <button type={"submit"}
                className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-base" 
                disabled={!formRenderProps.allowSubmit}
                >Save</button>
                <button type={"button"} onClick={closeDialog}
                  className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-base cancel-btn" 
                  >Cancel</button>
            </div>
            <div className="pass-reqs">
              <h4>Password Requirements</h4>             
              <p>The password must not contain the account name of the user.</p>
              <p>The password needs to be at least eight characters long, and less than 128 characters long.</p>
              <p>The password must contain characters from three of the following four categories:</p>
              <ul>
                <li>Latin uppercase letters (A through Z)</li>
                <li>Latin lowercase letters (a through z)</li>
                <li>Base 10 digits (0 through 9)</li>
                <li>Non-alphanumeric characters such as: exclamation point (!), dollar sign ($), number sign (#), or percent (%)</li>
              </ul>
            </div>
          </fieldset>
        </FormElement>
        )}
         />    
      </Dialog>    
    </div>
  );
};
export default ChangeUserPasswordComponent;