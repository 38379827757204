import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
// import { RootState } from '../store';
import { fetchConfig } from './configAPI';
import {IConfig, IConfigState, IRole, ICounty} from '../interfaces/commonInterfaces';
import {RootState} from '../store';


const initialState: IConfigState = {
    status: "unloaded",
    config: undefined
};

export const fetchConfigAsync = createAsyncThunk(
  'config/fetchConfig',
  async (_, thunkAPI) => {
    // let s =  thunkAPI.getState() as RootState;
    // let config = s.config;
    // console.log(config);
    // if (config.status === "unloaded"){
        const response = await fetchConfig();    
        return response.data;
    // }
    // if (config.status === "loading"){
    //     return undefined;
    // }
    // else {
    //     return config.config;
    // }
  }
);

export const configSlice = createSlice({
    name: 'myconfig',
    initialState,
    // The `reducers` field lets us define reducers and generate associated actions
    reducers: {
        setRoles: (state, action: PayloadAction<IRole[]>) => {
            if(state.config !== undefined) state.config!.lookups.roles = action.payload;
        },
        setCounties: (state, action: PayloadAction<ICounty[]>) => {
            if(state.config !== undefined) state.config!.lookups.counties = action.payload;
        }
    },
    extraReducers: (builder) => {
      builder
        .addCase(fetchConfigAsync.fulfilled, (state, action) => {
          state.config = action.payload;
          state.status = "loaded";
        })           
        .addCase(fetchConfigAsync.pending, (state, action) => {            
            state.status = "loading";
          })    
      },
  });
  
  export const { setRoles, setCounties } = configSlice.actions;
  
  export default configSlice.reducer;
  