import { createSlice, PayloadAction, createAsyncThunk } from '@reduxjs/toolkit';
import { authenticateUser } from './authenticationAPI';
import {setNavigationByName, setNavigationByIFrame, setNavigationByTabId, closeNavigationDialog, 
  openNavigationDialog, startLoader, endLoader} from '../navigation/navigationSlice';
import {addErrorNotification} from '../notification/notificationSlice';
import {saveUserAsync} from '../userManagement/userManagementSlice';
import {IAuthenticationState, ILogin, IConfigState, IDialogState} from '../interfaces/commonInterfaces';
import {RootState} from '../store';

const initialState: IAuthenticationState = {
  status: 'loggedOut',
  userLoginInfo: {
    canDownload: false,
    canUpload: false,
    isAdmin: false,
    county: "",
    isValid: false
  },

};

export const loginUserAsync = createAsyncThunk(
  'authentication/loginUserAsync',
  async (login: ILogin, thunkAPI) => {
    let s =  thunkAPI.getState() as RootState;
    let config = s.config;
    try {     
      thunkAPI.dispatch(startLoader());
      const response = await authenticateUser(config.config!, login);    

      var expiresIn = 5;
      if (response.mapTokenExpiresIn !== undefined) expiresIn = response.mapTokenExpiresIn;
      
      response.expires = new Date();
      response.expires.setSeconds(response.expires.getSeconds() + expiresIn);
      
      thunkAPI.dispatch(endLoader());
      return response; 
    } catch (error) {
      thunkAPI.dispatch(addErrorNotification("There was an error connecting to authenticate."));
      thunkAPI.dispatch(endLoader());
      return {
        canDownload: false,  
        canUpload: false,
        isAdmin: false,
        county: "",
        isValid: false      
      };
    }
  }
);

export const authenticationSlice = createSlice({
  name: 'myconfig',
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    // getAuth: (state) => {
    //   // if (state.status === "unloaded"){
    //   //   //state.config = fetchConfig();
    //   // }
    // },
  },
  
  // The `extraReducers` field lets the slice handle actions defined elsewhere,
  // including actions generated by createAsyncThunk or in other slices.
  extraReducers: (builder) => {
    builder
      .addCase(setNavigationByName, (state, action: PayloadAction<string>) => {    
        if (action.payload === "LOGOUT"){
          state.status = 'loggedOut';
        }   
        else {
          const now = new Date();
          if (state.userLoginInfo!.expires! < now){
            state.status = 'loggedOut';
          }
        }                 
      })
      .addCase(setNavigationByTabId, (state, action: PayloadAction<number>) => {    
        const now = new Date();
          if (state.userLoginInfo!.expires! < now){
            state.status = 'loggedOut';
          }   
      })
      
      .addCase(setNavigationByIFrame, (state, action: PayloadAction<string>) => {    
        const now = new Date();
          if (state.userLoginInfo!.expires! < now){
            state.status = 'loggedOut';
          }           
      })
      .addCase(openNavigationDialog, (state, action: PayloadAction<IDialogState>) => {    
        const now = new Date();
          if (state.userLoginInfo!.expires! < now){
            state.status = 'loggedOut';
          }    
      })
      .addCase(closeNavigationDialog, (state) => {    
        const now = new Date();
          if (state.userLoginInfo!.expires! < now){
            state.status = 'loggedOut';
          }        
      })
      .addCase(loginUserAsync.fulfilled, (state, action) => {
        if (action.payload.isValid){
          state.status = 'loggedIn';
          state.userLoginInfo = action.payload;
        }
        else{          
          state.status = 'denied';
          state.userLoginInfo = action.payload;
        }
        //thunkAPI.dispatch(endLoader());
      })
      .addCase(loginUserAsync.pending, (state) => {
        state.status = 'loggingIn';
      })
      .addCase(loginUserAsync.rejected, (state) => {
        state.status = 'denied';
        //thunkAPI.dispatch(endLoader());
      })
      .addCase(saveUserAsync.fulfilled, (state, action) => {
        if (action.payload.isSuccessful && action.meta.arg.id === state.userLoginInfo!.userDetail!.id)
        state.userLoginInfo!.userDetail = action.meta.arg;
      });
    }
  });

//export const { getAuth } = authenticationSlice.actions;

export default authenticationSlice.reducer;
