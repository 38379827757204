import React, { useState, useEffect } from "react";

// thunk imports
import { useAppSelector, useAppDispatch } from '../../app/hooks';
import { closeNavigationDialog } from '../../app/navigation/navigationSlice';
import {getOrgsAsync, saveUserAsync, refreshData} from '../../app/userManagement/userManagementSlice';
import {ValidatedComboBox, ValidatedDropdown, ValidatedInput} from '../commonControls/validationControls/ValidationControlComponent'
import {IRole, IUserDetail, IBaseResult} from '../../app/interfaces/commonInterfaces';

import { Checkbox } from "@progress/kendo-react-inputs";
import { Error } from "@progress/kendo-react-labels";

import { Form, Field, FormElement, FormRenderProps } from "@progress/kendo-react-form";
import { Dialog } from "@progress/kendo-react-dialogs";
import { filterBy, FilterDescriptor } from "@progress/kendo-data-query";
import { ComboBoxFilterChangeEvent } from "@progress/kendo-react-dropdowns";

import { addErrorNotification, addSuccessNotification } from "../../app/notification/notificationSlice";


interface RoleCheckboxParams extends IRole {
  isChecked: boolean;  
  onChange(name: any): any;
  disabled: boolean;
}

function RoleCheckbox(props: RoleCheckboxParams) {
  return (<Checkbox name={"role_" + props.id} checked={props.isChecked} label={props.roleName} onChange={props.onChange} disabled={props.disabled} />)
}

export function UserDetailComponent() {  
  const dispatch = useAppDispatch();
  const editedUser: IUserDetail = useAppSelector(state => state.navigation.dialogState.data);
  const [orgs, setOrgs] = React.useState<string[]>([]);
  const [allOrgs, setAllOrgs] = React.useState<string[]>([]);
  const [orgLoaded, setOrgLoaded] = React.useState<boolean>(false);
  const [errorMessage, setErrorMessage] = React.useState<string>("");
  
  const isAdmin = useAppSelector(state => state.authInfo.userLoginInfo?.isAdmin);
  const config = useAppSelector(state => state.config.config);
  const counties = config!.lookups.counties;
  const roles = config!.lookups.roles; 
  
  useEffect(() => {
    if (!orgLoaded){
      setOrgLoaded(true);
      dispatch(getOrgsAsync()).then(result =>{
        const lst = (result.payload as string[]);
        setAllOrgs(lst);
        setOrgs(lst);
      });
    }    
  }, [orgLoaded, dispatch]);
  
  const [user, setUser] = useState<IUserDetail>(editedUser);
  

  const closeDialog = () => {
    dispatch(closeNavigationDialog());
  };

  const handleRoleCheckboxChange = (event:any) => {
    const target = event.target;
    
    const roleId = Number(target.name.replace("role_", ""));
    const role = roles.find(r=> r.id === roleId)!;

    setUser({...user, roles: updateRole(user, role)});
  }

  const handleSubmit = (dataItem: { [name: string]: any }) => {
    // the roles are treated differently, so we need to merge it with what came from the form
    const { id, roles } = user;
    let upUser: IUserDetail = {...(dataItem as IUserDetail),  roles, id};
    
    dispatch(saveUserAsync(upUser)).then((result)=> {
      const r = (result.payload as IBaseResult);

      if (r.isSuccessful){          
        dispatch(refreshData());
        dispatch(addSuccessNotification("the user was successfully saved"));
        closeDialog();
        setErrorMessage("");
      }
      else{
        dispatch(addErrorNotification("there was an issue saving the user: " + r.message));
        console.log(r.message);
        setErrorMessage(r.message);
      }
    }); 
  }
  
  const updateRole = (userDetail: IUserDetail, role: IRole) : IRole[]=> {
    // making a copy of the role
    let roles = userDetail.roles.map(r=> r);
    const i = roles.findIndex((r : IRole) => r.id === role.id);
    
    if (i < 0){
      roles.push(role);
    }
    else{
      roles.splice(i, 1);
    }
    return roles;
  }

  const hasRole = (userDetail: IUserDetail, id: number): boolean => {
    return userDetail.roles.findIndex((r : IRole) => r.id === id) >= 0;
  }

  const emailRegex: RegExp = new RegExp(/\S+@\S+\.\S+/);
  const emailValidator = (value: string) => emailRegex.test(value) ? "" : "Please enter a valid email";
  const requiredValidator = (value: string) => { 
    const r = (value !== "" && value !== undefined) ? "" : "Please enter a value";
    //if (r !== "") console.log("validation issue", value, r);
    return r;
  };

  const filterData = (filter: FilterDescriptor) => {
    const data = allOrgs.slice();
    return filterBy(data, filter);
  };

  const filterOrgChange = (event: ComboBoxFilterChangeEvent) => {
    setOrgs(filterData(event.filter));
  };
  
  return ( 
    <div>
      <Dialog title={`User Details`} onClose={closeDialog}>
        <Form initialValues={user}
        onSubmit={handleSubmit}
        render={(formRenderProps: FormRenderProps) => (        
          <FormElement style={{ maxWidth: 650 }}>
            <fieldset className={"k-form-fieldset initial-fieldset"}>
            <legend className={"k-form-legend"}></legend>
              <div className="mb-3">
                <Field
                  name={"firstName"}
                  component={ValidatedInput}
                  label={"First Name"}
                  validator={requiredValidator}
                />
              </div>
              <div className="mb-3">
                <Field
                  name={"lastName"}
                  component={ValidatedInput}
                  label={"Last Name"}
                  validator={requiredValidator}
                />
              </div>
              <div className="mb-3">
                <Field
                  name={"userName"}
                  component={ValidatedInput}
                  label={"User Name"}
                  validator={requiredValidator}
                />
              </div>
              <div className="mb-3">
                <Field
                  name={"email"}
                  component={ValidatedInput}
                  label={"Email"}
                  validator={emailValidator}
                />
              </div>
              
            <div className="mb-3">
              <Field component={Checkbox} name="status" label="Is Active" disabled={!isAdmin} />            
            </div>   

            <fieldset className={"k-form-fieldset"}>
            <legend className={"k-form-legend"}>Roles</legend>
              <div className="mb-3 flex-mode">
                <div className="flex-col">
                  {roles.filter(r=> r.group === "Administrator").map((role, index: number) => {                         
                    return (
                      <Field name={"role_" + role.id} component={RoleCheckbox} {...role} roleName={role.roleName} 
                        isChecked={hasRole(user, role.id)} key={"role_" + index } onChange={handleRoleCheckboxChange} disabled={!isAdmin} /> 
                        );
                  })}  
                </div>
                
                <div className="flex-col">
                  {roles.filter(r=> r.group === "Level").map((role, index: number) => {                         
                    return (                  
                      <Field name={"role_" + role.id} component={RoleCheckbox} {...role} roleName={role.roleName} 
                        isChecked={hasRole(user, role.id)} key={"role_" + index } onChange={handleRoleCheckboxChange} disabled={!isAdmin} /> );
                  })}  
                </div>
                
                <div className="flex-col">
                  {roles.filter(r=> r.group === "Group").map((role, index: number) => {                         
                    return (                  
                      <Field name={"role_" + role.id} component={RoleCheckbox} {...role} roleName={role.roleName} 
                        isChecked={hasRole(user, role.id)} key={"role_" + index } onChange={handleRoleCheckboxChange} disabled={!isAdmin} /> );
                  })}    
                </div>
              </div>       
            </fieldset>
            <div className="mb-3">                   
                <Field
                  data={orgs} allowCustom={true} name="organization"
                  value={user.organization}
                  component={ValidatedComboBox}
                  filterable={true}
                  onFilterChange={filterOrgChange}
                  label={"Organization"}
                  validator={requiredValidator}
                />
            </div>  
            <div className="mb-3">                    
                <Field style={{ width: "100%" }} value={user.county} name="county"
                textField="countyName" dataItemKey="id" data={counties} disabled={!isAdmin}
                  component={ValidatedDropdown}
                  label={"County"}
                  validator={requiredValidator}
                />                
            </div>  
            <div className="mb-3">
              {(errorMessage !== "") && <Error>{errorMessage}</Error>}
            </div>
            </fieldset>
            <div className="k-form-buttons">
              <button
                type={"submit"}
                className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-base"
                disabled={!formRenderProps.allowSubmit}
              >
                Save
              </button>
                <button type={"button"} onClick={closeDialog}
                  className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-base cancel-btn" 
                  >Cancel</button>
            </div>
          </FormElement>
        )}
        />
      </Dialog>    
    </div>
  );
}
