import { createSlice, PayloadAction, createAsyncThunk } from '@reduxjs/toolkit';
import { getUsers, getAllRoles, getDistinctOrgs, getCounties, saveUser, deleteUser, updatePassword, resetPassword } from './userManagementAPI';
import {endLoader, setNavigationByName, startLoader} from '../navigation/navigationSlice';
import {IUserManagementState, ILogin, IUserDetail, IConfigState} from '../interfaces/commonInterfaces';
import {setRoles, setCounties} from '../config/configSlice';
import {RootState} from '../store';

const initialState: IUserManagementState = {
    userStatus:'unloaded',
    // users: [],
    // orgs:[],
    // roles:[],
    // counties:[],
    // statuses:["Inactive", "Active"]
};

export const getUsersAsync = createAsyncThunk(
  'userManagement/getUsersAsync',
  async (_, thunkAPI) => {        
    
    let s =  thunkAPI.getState() as RootState;
    let config = s.config;
    const response = await getUsers(config.config!);

    // we need to convert a string date to the actual date type
    response.forEach(u=> {
      if (u.createDate != null) u.createDate = new Date(u.createDate);
      if (u.lastAccessDate != null) u.lastAccessDate = new Date(u.lastAccessDate);
    })
    return response;
  });

export const getRolesAsync = createAsyncThunk(
'userManagement/getRolesAsync',
async (_, thunkAPI) => {        
  
  let s =  thunkAPI.getState() as RootState;
  let config = s.config;
 
    const response = await getAllRoles(config.config!);
    thunkAPI.dispatch(setRoles(response));
    return response;
});

export const getCountiesAsync = createAsyncThunk(
'userManagement/getCountiesAsync',
async (_, thunkAPI) => {        
  
  let s =  thunkAPI.getState() as RootState;
  let config = s.config;
 
    const response = await getCounties(config.config!);
    thunkAPI.dispatch(setCounties(response));
    return response;
});

export const getOrgsAsync = createAsyncThunk(
'userManagement/getOrgsAsync',
async (_, thunkAPI) => {        
  
  let s =  thunkAPI.getState() as RootState;
  let config = s.config;
 
    const response = await getDistinctOrgs(config.config!);
    return response;
});

export const saveUserAsync = createAsyncThunk(
    'userManagement/saveUserAsync',
    async (userDetail: IUserDetail, thunkAPI) => {       
      
    let s =  thunkAPI.getState() as RootState;
    let config = s.config;
      
      thunkAPI.dispatch(startLoader());
      const response = await saveUser(config.config!, userDetail);
      //console.log(response);
      thunkAPI.dispatch(endLoader());
      return response;
});

export const deleteUserAsync = createAsyncThunk(
    'userManagement/deleteUserAsync',
    async (userDetail: IUserDetail, thunkAPI) => {        
      
    let s =  thunkAPI.getState() as RootState;
    let config = s.config;
       
      thunkAPI.dispatch(startLoader());
      const response = await deleteUser(config.config!, userDetail);
      thunkAPI.dispatch(endLoader());
      return response;
});

export const updatePasswordAsync = createAsyncThunk(
    'userManagement/updatePasswordAsync',
    async (login: ILogin, thunkAPI) => {     
      
    let s =  thunkAPI.getState() as RootState;
    let config = s.config;
          
      thunkAPI.dispatch(startLoader());
      const response = await updatePassword(config.config!, login);
      thunkAPI.dispatch(endLoader());
      return response;
});


export const resetPasswordAsync = createAsyncThunk(
  'userManagement/resetPasswordAsync',
  async (login: ILogin, thunkAPI) => {       
    
    let s =  thunkAPI.getState() as RootState;
    let config = s.config;
      
    thunkAPI.dispatch(startLoader());
    //console.log(login);
    const response = await resetPassword(config.config!, login);
    thunkAPI.dispatch(endLoader());
    return response;
});
export const userManagementSlice = createSlice({
  name: 'myconfig',
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    refreshData: (state) => {
        state.userStatus = 'refresh';
    }
  },
  
  // The `extraReducers` field lets the slice handle actions defined elsewhere,
  // including actions generated by createAsyncThunk or in other slices.
  extraReducers: (builder) => {
    builder
      .addCase(setNavigationByName, (state, action: PayloadAction<string>) => {    
        if (action.payload === "LOGOUT"){
          state.userStatus = 'unloaded';          
        }            
      })
      .addCase(getUsersAsync.pending, (state) => {
        state.userStatus = 'loading';
      })      
      .addCase(getUsersAsync.fulfilled, (state, action) => {
        if (action.payload){
            state.userStatus = 'loaded';
        }
      });
      
    }
  });

  
export const { refreshData } = userManagementSlice.actions;

export default userManagementSlice.reducer;
