import { useEffect } from "react";

import {ILinkListItem, ILinkList} from "../../../app/interfaces/commonInterfaces";

// card info
import {Card, CardHeader, CardTitle, CardBody } from '@progress/kendo-react-layout';

import { useAppSelector, useAppDispatch } from '../../../app/hooks';
import { setNavigationByIFrame  } from '../../../app/navigation/navigationSlice';

// the tab conponent
export function LinkListComponent(props: ILinkList | undefined) {  
  const userManageStatus = useAppSelector(state => state.userManagement.userStatus);

  if (props === undefined || props.links === undefined){
    return (<div>no config</div>);
  }

  return (
    <Card>
      <CardHeader>
        <CardTitle>{props.title}</CardTitle>
      </CardHeader>
      <CardBody>      
          {props.links.map((link, index: number) => {                         
            return (<LinkListItem {...link} key={"List_" + index } />) 
          })}
      </CardBody>
    </Card>
    
  )
}


// create the link list item
function LinkListItem(props: ILinkListItem) {
  const dispatch = useAppDispatch();
  
  const countyName = useAppSelector(state => state.authInfo.userLoginInfo?.userDetail?.county?.countyName);
  const { label, url, openNewTab } = props;
  
  // fires when the app bar items are clicked
  // will call set navigation by name
  const handleClick = (e: any) => {
    // replace the county with the user's county
    const u = url.replace("{county}", countyName?countyName:"");
    
    if (!openNewTab){
      dispatch(setNavigationByIFrame(u));
    } 
    else   
    {
      window.open(u, "_blank");
    }
  };

  return (
    <ul>
      <button onClick={handleClick}>{label}</button>
    </ul>
  );
}

