import React, { useEffect } from "react";

// import the thunk
import { useAppSelector, useAppDispatch } from './app/hooks';
import { setNavigationByName, openNavigationDialog } from './app/navigation/navigationSlice';
import { getRolesAsync, getCountiesAsync } from './app/userManagement/userManagementSlice';
import {fetchConfigAsync } from './app/config/configSlice';

// components
import { MainPanelComponent } from "./features/mainPanel/MainPanelComponent";
import { LoginComponent } from "./features/login/LoginComponent";
import { NotificationComponent } from './features/notificationControl/NotificationComponent';

import { Menu, MenuItem } from "@progress/kendo-react-layout";
import { Loader } from "@progress/kendo-react-indicators";
import { Dialog } from "@progress/kendo-react-dialogs";
import { ProgressBar } from "@progress/kendo-react-progressbars";

import '@progress/kendo-theme-default/dist/all.css';
import './App.scss';
import './App_KendoOverrides.scss';
//import { config } from "./config";


export function App() {  
  const dispatch = useAppDispatch();
  
// dispatch(fetchConfigAsync());

  const configStatus = useAppSelector(state => state.config.status);
  const config = useAppSelector(state => state.config.config);

  const authStatus = useAppSelector(state => state.authInfo.status);
  const loginInfo = useAppSelector(state => state.authInfo.userLoginInfo);
  const loader = useAppSelector(state=> state.navigation.loader);
  const progress = useAppSelector(state=> state.navigation.progress);
  const currentYear = new Date().getFullYear();
  
  const currentUser = loginInfo?.userDetail;

  const [configUnloaded, setConfigUnloaded] = React.useState(true); 

  useEffect(() => {
    if (configUnloaded){
      setConfigUnloaded(false);
      dispatch(fetchConfigAsync());
    }
  }, []);

  useEffect(() => { 
    if (loginInfo?.isValid){
      // initialize stuff here
      //setFirstLoad(false);
      dispatch(getRolesAsync());
      dispatch(getCountiesAsync());
    }

    // if (loginInfo?.isValid && loginInfo?.isAdmin && userListstatus == 'unloaded' ){
    //   dispatch(getUsersAsync());
    // }
  }, [loginInfo, dispatch]);
  
  // fires when the app bar items are clicked
  // will call set navigation by name
  const navigateToPage = (e: any) => {        
    const pageSelected = e.item.data || "";
    //console.log(pageSelected);

    switch (pageSelected) {
      case "USER_DETAIL":          
        dispatch(openNavigationDialog({
          name: "USER_DETAIL",
          data: currentUser,
          isOpen: true
        }));

        break;
      case "RESET_PASSWORD":                    
        dispatch(openNavigationDialog({
          name: "RESET_PASSWORD",
          data: currentUser,
          isOpen: true
        })); 
        break;    
      case "": // when empty don't do anything
        break;
      default:
        dispatch(setNavigationByName(pageSelected));
        break;
    }
  };

  // const openDialog = (e: any) => {
  //   const pageSelected = e.item.data;    
  //   dispatch(openNavigationDialog(pageSelected));    
  // }

  // will only display if the config isn't loaded.
  if (configStatus == "unloaded" || configStatus == "loading" || config === undefined){
    return (      
      <React.Fragment>
      Loading...
      </React.Fragment>
    )
  }

  // if logged out or denied, then display login screen
  if (authStatus === "loggedOut" || authStatus === "loggingIn" || authStatus === "denied")
    return (
      <React.Fragment>
        <div>
          <div className="App-header-module"><a href={config!.ncrUrl} target="_blank" rel="noreferrer"><img src="site_logo.png" /></a></div>
          <LoginComponent></LoginComponent>
          
          <NotificationComponent></NotificationComponent>
          {loader.isLoading && 
            <Dialog>
              <Loader  />
              <div>{loader.message}</div>
            </Dialog>
          }
        </div> 
      </React.Fragment>
    );
    
  // if (authStatus === "loggingIn") {    
  //   return (
  //     <h2>Loading...</h2>
  //   )
  // }
  
  return (
    <div>
      <div className="App-header-module"><a href={config!.ncrUrl} target="_blank" rel="noreferrer"><img src="site_logo.png" /></a></div>

      <div className="App">
        <header className="App-header">
          <Menu onSelect={navigateToPage}>
            <MenuItem text="Help" data="HELP"></MenuItem>
            <MenuItem text="Contact Us" data="CONTACT_US"></MenuItem>
            <MenuItem text={ (currentUser !== undefined) ? currentUser.firstName + " " + currentUser.lastName : "Not A Valid User"}>
              <MenuItem text='My Account' data="USER_DETAIL"></MenuItem>
              <MenuItem text='Change Password' data="RESET_PASSWORD"></MenuItem>
              <MenuItem text='Log Out' data="LOGOUT"></MenuItem>
            </MenuItem>
          </Menu>      
        </header>
        <div className="App-content">
          <MainPanelComponent></MainPanelComponent>
          <NotificationComponent></NotificationComponent>
          <div className="footer"><p>&copy; {currentYear} North Central Region (NCR)</p></div>    
      </div> 
        
        
        {loader.isLoading && 
          <Dialog>
            <Loader  />
            <div>{loader.message}</div>
          </Dialog>
        } 

        {progress.isProgress && 
          <Dialog>
            <ProgressBar value={progress.progressComplete} max={100} />
            <div>{progress.message}</div>
          </Dialog>

        }
      </div> 
    </div>   
  );
}

// Component export
export default App;
