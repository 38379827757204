// import the thunk
import { useAppSelector, useAppDispatch } from '../../app/hooks';

// import components
import { WelcomeComponent } from '../welcome/WelcomeComponent';
import { DownloadComponent } from '../download/DownloadComponent';
import { MapComponent } from '../map/MapComponent';
import { UploadComponent } from '../upload/UploadComponent';
import { UserManagementComponent } from '../userManagement/UserListComponent';
import { UserDetailComponent } from '../userManagement/UserDetailComponent';
import { HelpComponent } from '../help/HelpComponent';
import { ContactUsComponent } from '../contactUs/ContactUsComponent';

// import the kendo controls
import {TabStrip, TabStripTab, TabStripSelectEventArguments} from '@progress/kendo-react-layout';

// import the navigation 
import { setNavigationByTabId } from '../../app/navigation/navigationSlice';
import ChangeUserPasswordComponent from "../userManagement/ChangeUserPasswordComponent";

// the tab conponent
export function MainPanelComponent() {  
  const dispatch = useAppDispatch();
  const authInfo = useAppSelector(state => state.authInfo);
  const navigation = useAppSelector(state=> state.navigation);
  
  const handleSelect = (e: TabStripSelectEventArguments) => {
    dispatch(setNavigationByTabId(e.selected));
  };

  return (
    <div className='main-holder'>
      <TabStrip selected={navigation.tabId} onSelect={handleSelect}>
        <TabStripTab title="Welcome">
          <WelcomeComponent />
        </TabStripTab>
        
        {authInfo.userLoginInfo?.canDownload && 
          <TabStripTab title="Download">
            <DownloadComponent />
          </TabStripTab>
        }
        
        {authInfo.userLoginInfo?.canUpload && 
          <TabStripTab title="Upload">
            <UploadComponent />
          </TabStripTab>
        }

        <TabStripTab title="Map">          
          <MapComponent />
        </TabStripTab>
        
        {authInfo.userLoginInfo?.isAdmin && 
          <TabStripTab title="Site Administration">
            <UserManagementComponent />
          </TabStripTab>
        }        
      </TabStrip>
      {navigation.name === "HELP" &&
        <HelpComponent />
      }
      {navigation.name === "CONTACT_US" &&
        <ContactUsComponent />
      }
      {navigation.dialogState.name === "USER_DETAIL" && navigation.dialogState.isOpen &&
        <UserDetailComponent />
      }
      {navigation.dialogState.name === "RESET_PASSWORD" && navigation.dialogState.isOpen &&
        <ChangeUserPasswordComponent />
      }
      {navigation.name === "IFRAME" &&
        <iframe src={navigation.iframeUrl} title={navigation.iframeUrl} />
      }
    </div>    
  );
}
