export function HelpComponent() {  
  return (         
  <div className="host-page">
    <h1>NCR GIS Data Repository Help</h1>
    <div className="flex-holder">
      <div className="column">
        <h2>User Access</h2>
        <h3>Level 1</h3>
        <p>The user will access the application through the logon screen, pictured below:</p>
        <p>Upon entering in a valid user name and password, the user will be directed to the main application page, seen below:</p>
        <p>The initial page is the home page of the application. This page will contain news and information about the site and spatial data layers available on the site. The news stories on this home page are editable by users with the necessary permissions. The Map Viewer link is available to all user levels. The functioning of the Map Viewer is described in Section 2.2. All users will have the ability to request user access for other users under the Site Administration link in the main navigation panel on the left. For user levels 1-3, the Site Administration page will appear as follows:</p>
        <p>The page for requesting user access is seen below:</p>
        <p>Users can request access for others by filling out the form and pressing Submit. The NCR Administrator will receive an email detailing the request and will either approve or deny the request. If the request is approved, the new user will receive an email with instructions for accessing the site. If the user request is denied, the requestor will receive an email with instructions for contacting the NCR administrator.</p>
        <p>Users can change their user information by selecting the My Account link. The My Account page is seen below:</p>
        <p>Users can change all their information except their email address, which serves as their user name. In order to change their email address, users must be deleted from the system and re-register. Users can change their password by selecting the link at the bottom of the page. After changing their password, the user will be required to log back in using their new password.</p>
        <h3>Level 2</h3>
        <p>Level 2 user access includes all the capabilities of Level 1 access plus the ability to download data. The Download Data page can be seen below:</p>
        <p>Users have the ability to define their area of interest in four different ways: by municipality, by county, by point and radius, and by rectangle. For the latter two, users can use the map viewer to define their area of interest interactively on a map. This functionality is described in Section 2.3. The user can select the method of area definition by selecting one of the radio buttons on the left side of the page. The user can then select the layers to be downloaded, the coordinate system, and the schema in which to download the data. The user’s email will appear by default in the Email field. The user can overtype this with any email address. If the user desires to define their area of interest by point radius or rectangle, they can enter these values in by hand (in UTM NAD83 Zone 13 coordinates) or they can choose to define them interactively on the map viewer. When the user hits the Submit Download Request button, they will be informed that their download request has been received, and that they will receive an email when their data download is ready. This email will also contain a link to download their data request, as seen below:</p>
        <p>From the Data Download page, users can select the Get Last Data Upload Date link at the bottom of the page. There they can see the last data upload date for each layer for each county. This page can be seen below:</p>
        <h3>Level 3</h3>
        <p>Level 3 user access includes all the capabilities of Level 2 access plus the ability to upload data. The Upload Data page can be seen below:</p>
        <p>Users with Level 3 access must be associated with a county. When they access the Data Upload page, they have the option of uploading either a personal geodatabase (mdb) or the same zipped up (zip). Upload parameters, such as source data coordinate system and upload schema, is determined automatically based on the user’s county affiliation.. When the user selects the Browse button and browses to the location of the upload file, then hits the Submit button, they will be directed to a web page indicating that their upload has been received and that they will receive an email when it is complete. The email will include a link to the location of the translation log file, as seen below:</p>
        <h3>Level 4</h3>
        <p>Users with Level 4 access will have the ability to administer access to the application, create, edit, and delete news stories, and delete and edit existing users. When a user with level 4 access clicks the Site Administration link in the main navigation bar, they have two more options than other users of the sire, as seen below:</p>
        <p>Users with Level 4 access can grant or deny user access and edit or delete existing users. When the user selects the Grant User Access link, they will be taken to a screen similar to the one shown below:</p>
        <p>From this screen they can choose to grant or deny access to the user in the list. If access is granted by selecting the Grant link, the user will be added to the user table in the database and that user will receive an email with instructions how to access the site, as seen below:</p>
        <p>The administrator can deny access to the site if there is no compelling reason for the individual to have access or if the access level is inappropriate. If the user is denied access to the site, the individual who requested access for that person will receive an email indicating that their request for access has been denied, as seen below:</p>
        <p>If there are no users waiting to be approved for access, this will be indicated on the page.</p>
        <p>Users with Level 4 access can also create and edit news stories for the NCR News page of the application. In order to add or edit a news story, users with Level 4 access can either click on the “…see full story” link or the “Read all news articles” link. The user can then choose to either edit or delete and existing news story or create a new one. In either case, they will be directed to a page similar to the one shown below:</p>
        <p>From this page the user can either edit or create a news story and hit the Submit button.</p>
      </div>
      <div className="column">
        <h2>Map Viewer</h2>
        <p>A map viewer will be provided for repository users. This map viewer will be available to all users of the application. Map layers can be controlled in the Map Contents panel on the left side of the application. Most layers can be turned on and off and users can view the symbology. This map viewer can be accessed by selecting Map Viewer from the primary navigation bar on the left. A specialized version of the map viewer with additional functionality can be accessed from the Download page. The initial state of the base map viewer can be seen below:</p>
        <p>The map viewer controls the amount of processing time for each map image by limiting the amount of data that can be viewed at particular map scales or zoom levels. When zoomed to the full extents, only a handful of layers are visible. The visible layers will change as the user zooms in and out.</p>
        <h4>Toolbar</h4>
        <p>At the top left of the map viewer is the toolbar. The tools and their functioning are described below.</p>
        <h4>Zoom In</h4>
        <p>The Zoom In tool allows the user to zoom in to a smaller portion of the map. The user clicks and holds down the left mouse button anywhere in the map pane and drags a box defining the area they wish to zoom in to. The user can also click once anywhere in the map pane and the extent will change to be zoomed in and centered on that point.</p>
        <h4>Zoom Out</h4>
        <p>The Zoom Out tool allows the user to zoom to a larger portion of the map. The user can select the Zoom Out tool and click anywhere in the map pane. The extent will changed to be zoomed out and centered on that point.</p>
        <h4>Pan</h4>
        <p>The Pan tool allows the user to change the map extents without changing the zoom level. The user clicks and holds down the left mouse button anywhere in the map pane and drags the map up, down, left, or right. The map will redraw centered on the new location.</p>
        <h4>Zoom to Full Extent</h4>
        <p>The Zoom to Full Extent tool allows the user to zoom to the maximum extent for the map services that make up the Map It! application. This happens to be the entire State of Wyoming.</p>
        <h4>Identify</h4>
        <p>The Identify tool allows the user to click on a location on the map and return information about features that are found at that location. When the Identify tool is selected and used to click a location on the map, the results for that location will appear in the Results pane on the left side of the application, as seen below:</p>
        <p>Only layers which are currently visible and have features in the clicked location will appear in the list. By clicking the “plus sign” on the left, more information about the individual features can be seen. Users can clear the Results pane by right-clicking on the Identify results and selecting Remove from the drop-down menu.</p>
        <h4>Measure</h4>
        <p>The Measure tool allows the user to measure distance and area for user-defined features on the map. This tool also allows the user to determine the coordinates (in UTM NAD 83, Zone 13) of any point on the map. When the measure tool is selected, the Measure dialog box appears. This box can be seen below:</p>
        <p>By default the option to measure a linear distance is selected. The user can click a location on the map, then click again on the map and the distance between the two points will appear in the dialog box. The user can continue clicking, creating a path for which they can see the total length and the length of the last segment created at any given point. The user can choose their distance units from a drop-down list. When the line to be measured is complete, the user can double-click on the last point. The line will remain on the map and the user can change the units if desired. The line will disappear when the dialog box is dismissed.</p>
        <p>By selecting the polygon tool on the right, the user can similarly create a polygon or area for which they will receive both perimeter and area calculations. Again, the user can select the units for both linear and areal measurements. When the user is finished with the area to be measured, they can double-click the last point. They can then change the measurement units if desired. The Measure by line panel can be seen below:</p>
        <h4>Tasks</h4>
        <p>In addition to the tools available on the toolbar, there are also tasks found in the Tasks panel in the upper left.</p>
        <h4>Export Map</h4>
        <p>The Export Map task allows the user to export the current map view in a variety of formats. The initial Export Map panel can be seen below:</p>
        <p>The user selects a template (currently there is only one available) and an output format. The output definition panel for the JPG format is shown below:</p>
        <p>The user enters in the desired title for the map in the Map Title field, selects the rest of the parameters, and presses Export. The map output will be created and a link will appear in the results panel, as seen below:</p>
        <p>Users can right-click on the link and save the map output to a local location.</p>
        <h2>Download Map Viewer</h2>
        <p>From the Download Data page in the main application, users can define their area of interest by selecting the Define on Map button. This will open up a map viewer with an additional tool, the Download Data tool.</p>
        <h4>Download Data</h4>
        <p>The Download Data tool is available in the map viewer accessed from the Data Download page and allows the user to define the area of interest for download. When the user selects this tool, the Download Data dialog appears, as seen below:</p>
        <p>The user can select the area definition method from the panel. By selecting either the By Point/Radius or By Rectangle method, the user will be prompted to click on the map and either define a point and radius or the corners of a rectangle on the map. The values will be passed back to the dialog box, as seen below:</p>
        <p>When the user presses the GO button, the values will be passed back to the main Data Download page. Regardless of the download methodology chosen on the main Data Download page, the method chosen on the Download Data panel will be passed back to the main page as seen below:</p>
        <p>The user can change their selection at any time or re-define the area of interest using the Download Data tool.</p>
      </div>
    </div>
  </div>
  );
}
