import { Input, Checkbox } from "@progress/kendo-react-inputs";
import { Form, Field, FormElement, FieldRenderProps, FormRenderProps } from "@progress/kendo-react-form";
import { Error } from "@progress/kendo-react-labels";
import { DropDownList, ComboBox } from "@progress/kendo-react-dropdowns";


export const ValidatedInput = (fieldRenderProps: FieldRenderProps) => {
    const { label, validationMessage, visited, ...others } = fieldRenderProps;

  return (
    <div>
      <label>{label}</label>
      <Input {...others} />
      {visited && validationMessage && <Error>{validationMessage}</Error>}
    </div>
  );
};

export const ValidatedComboBox = (fieldRenderProps: FieldRenderProps) => {
  const { label, validationMessage, visited, ...others } = fieldRenderProps;

  return (
    <div>
      <label>{label}</label>    
      
      <ComboBox {...others} />    
      
      {visited && validationMessage && <Error>{validationMessage}</Error>}
    </div>
  );
};

export const ValidatedDropdown = (fieldRenderProps: FieldRenderProps) => {
  const { label, validationMessage, visited, ...others } = fieldRenderProps;

  return (
    <div>
      <label>{label}</label>    
      
      <DropDownList {...others} />    
      
      {visited && validationMessage && <Error>{validationMessage}</Error>}
    </div>
  );
};