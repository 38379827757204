import React, { useState, useRef, useEffect } from "react";

// thunk imports
import { useAppSelector , useAppDispatch} from '../../app/hooks';
import { openNavigationDialog } from '../../app/navigation/navigationSlice';
import { getUsersAsync, getOrgsAsync, getRolesAsync, deleteUserAsync, refreshData } from '../../app/userManagement/userManagementSlice';

import {
  Grid,
  GridToolbar,
  GridColumn as Column,
  GridFilterChangeEvent,
  GridCellProps,
  GridFilterCellProps,
  GRID_COL_INDEX_ATTRIBUTE
} from "@progress/kendo-react-grid";
import { formatDate } from '@progress/kendo-intl';

import { filterBy, CompositeFilterDescriptor } from "@progress/kendo-data-query";
import { useTableKeyboardNavigation } from "@progress/kendo-react-data-tools";
import { DropdownFilterCell } from "../commonControls/gridControls/DropdownFilterCell";
import { MultiSelectFilterCell } from "../commonControls/gridControls/MultiSelectFilterCell";
import { ExcelExport, ExcelExportColumn } from "@progress/kendo-react-excel-export";

// import the style
import styles from './UserListComponent.module.css';
import { IDialogState, IUserDetail, IRole, IBaseResult } from "../../app/interfaces/commonInterfaces";

export function UserManagementComponent() {      
  const userManageDiv = useRef(null);
  const userManageStatus = useAppSelector(state => state.userManagement.userStatus);
  const userLoaded = useRef(false);
  const orgLoaded = useRef(false);
  
  const config = useAppSelector(state => state.config.config);
  const roles = config!.lookups.roles;
  const [orgs, setOrgs] = React.useState<string[]>([]);
  const [filter, setFilter] = React.useState<CompositeFilterDescriptor>();
  const [data, setData] = React.useState<IUserDetail[]>([]);
  const [fullUsers, setFullUsers] = React.useState<IUserDetail[]>([]);
  const statuses = ["Active", "Inactive"];
  const _export = React.useRef<ExcelExport | null>(null);
  const _grid = React.useRef<any>();

  const dispatch = useAppDispatch();
  
  useEffect(() => {
    if (!orgLoaded.current){
      orgLoaded.current = true;
      dispatch(getOrgsAsync()).then(result =>{
        setOrgs((result.payload as string[]));
      });
    }   
  }, []);

  useEffect(() => { 
    if (!userLoaded.current || userManageStatus === "refresh"){
      // initialize stuff here
      userLoaded.current = true;
      dispatch(getUsersAsync()).then((usrs) => {
        let ud = (usrs.payload as IUserDetail[]);
        ud = ud.map(u=> {
          u.rolesStr = u.roles.map(r=> r.roleName).join(", ");
          u.createDateStr = u.createDate?formatDate(u.createDate, "g"): "";
          u.lastAccessDateStr = u.lastAccessDate?formatDate(u.lastAccessDate, "g"): "";
          return u;
        });
        setFullUsers(ud);
        setData(ud);
      });
    }   
  }, [userManageStatus]);

  const openDialog = (user: IUserDetail, name: ("USER_DETAIL"|"RESET_PASSWORD")) => {
    const ud: IDialogState = {
      name: name,
      data: user,
      isOpen: true
    };
    dispatch(openNavigationDialog(ud));
  };

  const MyCommandCell = (props: GridCellProps) => {
    
    return (
      <td>
        <button
          className="k-button auxBtn auxBtn-edit"
          onClick={() => {
              openDialog(props.dataItem, "USER_DETAIL");
            }
          }
        >Edit</button>

        <button 
          className="k-button auxBtn auxBtn-delete"
          type={"button"} onClick={() =>{ 
              if (window.confirm("Are you sure you want to delete this user?")) {
                dispatch(deleteUserAsync(props.dataItem)).then((result)=>{
                  const r = (result.payload as IBaseResult);
                  if (r.isSuccessful){
                    dispatch(refreshData());
                  }
                  else{
                    window.alert(r.message);
                  }
                });
              }
              
            }
          }
        >Delete</button>

        <button 
          className="k-button auxBtn auxBtn-password"
          type={"button"} onClick={() =>{ 
            openDialog(props.dataItem, "RESET_PASSWORD");
            }
          }
        >Password</button>
        
      </td>
    );
  }


  const RoleCell = (props: GridCellProps) => {
    const value = props.dataItem["roles"].map((r:IRole) => r.roleName).join(", ");
    
    const navigationAttributes = useTableKeyboardNavigation(props.id);
    return (
      <td
        colSpan={props.colSpan}
        role={"gridcell"}
        aria-colindex={props.ariaColumnIndex}
        aria-selected={props.isSelected}
        {...{ [GRID_COL_INDEX_ATTRIBUTE]: props.columnIndex }}
        {...navigationAttributes}
      >
        {value === null ? [] : value}
      </td>
    );
  };
  
  const RoleFilterCell: any = (props: GridFilterCellProps) => (
    <MultiSelectFilterCell
      {...props}        
      data={roles.map(r=>r.roleName)}
      operatorFunc={RoleOperatorFunc}
    />
  );
   
  const RoleOperatorFunc = (roles: IRole[], values: string[]) : boolean => {
    // if value to sort on is empty then filter not applied
    if (values.length === 0 || values === undefined) return true;
  
    // look to see if a value is assigned to the role.  This is an AND operation
    var filteredArray = roles.filter((role)=> {
        return values.indexOf(role.roleName) !== -1;
    });
  
    return filteredArray.length === values.length;
  
    // this is an OR filter
    // return roles.findIndex(role => {
    //   return values.findIndex((value) => {
    //     return value === role.name
    //   }) >= 0;
    // }) >= 0;
  }
  
  
  const StatusFilterCell: any = (props: GridFilterCellProps) => (
    <DropdownFilterCell
      {...props}
      data={statuses}
      defaultItem={"All statuses"}
      operatorFunc={StatusOperatorFunc}
    />
  );
   
  const StatusOperatorFunc = (status: string, value: string) : boolean => {
    // if the value filtering on is empty, then the filter isn't applied.
    return status.toLowerCase() === value || value === "" || value === undefined;  
  }
  
  
  const OrgFilterCell: any = (props: GridFilterCellProps) => (
    <MultiSelectFilterCell
      {...props}
      data={orgs}
      operatorFunc={OrgOperatorFunc}
    />
  );
   
  const OrgOperatorFunc = (org: string, values: string[]) : boolean => {  
    if (values.length === 0) return true;
  
    // look to see if a value is assigned to the org.  This is an or operation
    return values.findIndex(v => v.toLowerCase() === org.toLowerCase()) >= 0;
  }

  const filterChange = (event: GridFilterChangeEvent) => {
    setData(filterBy(fullUsers, event.filter));
    setFilter(event.filter);
  };

  const excelExport = () => {
    if (_export.current !== null) {
      _export.current.save(data);
    }
  };

  return (         
  <div className={styles.userManageDiv + " leGrid-holder"} ref={userManageDiv}>
     <div className="le-newUser">
        <button className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-primary"
        onClick={() => {
            const item: IUserDetail = {
              id: 0,
              status: true,
              roles: [],
              county: undefined
            };
            openDialog(item, "USER_DETAIL");
          }
        }>Add New User</button>
        &nbsp;
        <button
            title="Export Excel"
            className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-primary"
            onClick={excelExport}
          >
            Export to Excel
          </button>
          
    </div>
    <div className="le-Grid">
      
      <ExcelExport ref={_export}>
        <ExcelExportColumn field="userName" title="User Name" />
        <ExcelExportColumn field="firstName" title="First Name" />
        <ExcelExportColumn field="lastName" title="Last Name" />
        <ExcelExportColumn field="statusName" title="Status" />
        <ExcelExportColumn field="rolesStr" title="Roles" />
        <ExcelExportColumn field="createDateStr" title="Member For" />
        <ExcelExportColumn field="lastAccessDateStr" title="Last Access"  />
        <ExcelExportColumn field="organization" title="Organization" />
      </ExcelExport>
        <Grid
          style={{ height: "620px" }}
          data={data}
          filterable={true}
          filter={filter}
          onFilterChange={filterChange}
          ref={_grid}
        >
          <Column cell={MyCommandCell} width={90} filterable={false} />
          <Column field="userName" title="User Name" />
          <Column field="firstName" title="First Name" />
          <Column field="lastName" title="Last Name" />
          <Column field="statusName" title="Status" filterCell={StatusFilterCell} />
          <Column field="roles" title="Roles" cell={RoleCell} filterCell={RoleFilterCell} />
          <Column field="createDate" title="Member For" filter="date" format="{0:g}" />
          <Column field="lastAccessDate" title="Last Access" filter="date" format="{0:g}" />
          <Column field="organization" title="Organization" filterCell={OrgFilterCell} />           
        </Grid>
    </div>
  </div>
  );
}
