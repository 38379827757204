import React from "react";

// thunk imports
import { useAppSelector, useAppDispatch } from '../../app/hooks';
import { ILinkList } from '../../app/interfaces/commonInterfaces';
import{ CallRestEndpoint} from "../../app/restHelper";
import {addSuccessNotification, addErrorNotification} from "../../app/notification/notificationSlice";


import {LinkListComponent}  from "../commonControls/linkList/LinkListComponent";

import { Upload } from "@progress/kendo-react-upload";
import { RadioGroup } from "@progress/kendo-react-inputs";
import { Error } from "@progress/kendo-react-labels";
import { Form, Field, FieldRenderProps, FormRenderProps } from "@progress/kendo-react-form";
import { startLoader, endLoader, startProgress, updateProgress, endProgress } from "../../app/navigation/navigationSlice";

import {loadAsync} from 'jszip'

const UploadInput = (fieldRenderProps: FieldRenderProps) => {
  const onChangeHandler = (event: any) => {
    fieldRenderProps.onChange({ value: event.newState });
  };
  const onRemoveHandler = (event: any) => {
    fieldRenderProps.onChange({ value: event.newState });
  };
  return (
    <Upload
      files={fieldRenderProps.value}  
      multiple={false}
      autoUpload={false}
      showActionButtons={false}
      {...fieldRenderProps}
      onAdd={onChangeHandler}
      onRemove={onRemoveHandler}
    />
  );
};

export function UploadComponent() {  
  const dispatch = useAppDispatch();
  const config = useAppSelector(state => state.config.config);

  const userDetail = useAppSelector(state => state.authInfo.userLoginInfo!.userDetail);
  let userCounty = userDetail?.county?.countyName??"";
  if (userCounty.toLowerCase() === "other"){
    userCounty = "";
  }

  const data = [
    { label: "Data", value: "data", extensions: ["zip"] },
    { label: "Schema", value: "schema", extensions: ["xlsx"] }
  ];

  const countyName = useAppSelector(state => state.authInfo.userLoginInfo!.userDetail!.county!.countyName);
  
  const [checked, setChecked] = React.useState(data[0].value);  
  const [extensions, setExtensions] = React.useState(data[0].extensions); 
  const [invalidFile, setInvalidFile] = React.useState(true); 

  // create the link info to pass to the component
  const uploadLink: ILinkList = {
    title: "Options",
    links: config!.uploadLinks
  };

  const handleChange = (e:any) => {
    const selVal = data.find(d=> d.value === e.value);
    setChecked(e.value);
    setExtensions(selVal!.extensions);
    setInvalidFile(false);
    
    console.log(e);
  };

  const deleteFiles = async (countyName: string) => {
    dispatch(startLoader("Cleaning up files"));
    console.log("deleting files");
    try {
      const deleteUrl = getUrl(config!.fmeConfig.deleteMethod, "", config!.fmeConfig.uploadDataToken);

      //+ '/fmerest/v2/resources/FME_SHAREDRESOURCE_DATA/filesys/NCR/' + countyName + '?&token=' + config.fmeConfig.uploadDataToken;
      const result = await CallRestEndpoint(deleteUrl, "DELETE", false)
     
      return true; 
    } catch (error:any) {
      if (error.response.data.reason === 'missing') return true;
      console.log(error);
      return false;  
    }
  }

  const uploadFileToServer = async (countyName: string, rawFile: any) => {    
    try{
      dispatch(startProgress("Uploading file"));
      const uploadUrl = getUrl(config!.fmeConfig.uploadDataMethod, "", config!.fmeConfig.uploadDataToken);
      //config.fmeConfig.baseUrl + '/fmerest/v2/resources/FME_SHAREDRESOURCE_DATA/filesys/NCR/' + countyName + '?createDirectories=true&detail=low&overwrite=true&token=' + config.fmeConfig.uploadDataToken
      
      const formData = new FormData();

      formData.append(
        "files", rawFile
      );

      console.log("uploading file");
      await CallRestEndpoint(uploadUrl, "POST", false, formData, {
          onUploadProgress: diplayUploadProgress
        }
      );
      dispatch(endProgress());
      return true;
    }
    catch (error: any){
      console.log(error);
      return false;  
    }
  }

  const unzipFile = async (countyName: string, zipFileName: string) => {
    dispatch(startLoader("unzipping file"));
    try{
      console.log("unzipping");

      const unzipUrl =  getUrl(config!.fmeConfig.unzipMethod, zipFileName, config!.fmeConfig.uploadDataToken); 
      //config.fmeConfig.baseUrl + '/fmejobsubmitter/NCR/Unzipper.fmw?County=' + countyName + '&ZipName=' + zipFileName + '&token=' + config.fmeConfig.uploadDataToken
      await CallRestEndpoint(unzipUrl, "POST", false, "", { headers: {
        "Content-Type": 'application/x-www-form-urlencoded'
      }
      });
      return true;
    }
    catch (error: any){
      console.log(error);
      return false;  
    }
  }
  
  const processFile = async (countyName: string, gdbFileName: string) => {
    dispatch(startLoader("processing the file"));
    try{
      console.log("processing file call");
      const processUrl = getUrl(config!.fmeConfig.processFileMethod, gdbFileName, config!.fmeConfig.uploadDataToken);
      // config.fmeConfig.baseUrl + 'fmejobsubmitter/NCR/Upload_' + countyName +'.fmw?SourceDataset_GENERIC=C%3A%5CProgramData%5CSafe%20Software%5CFME%20Server%5Cresources%5Cdata%5CNCR%5C' + 
      //   countyName + '%5C' + gdbFileName +
      //   '&opt_showresult=false&opt_servicemode=async&opt_requesteremail=' + email + '&token=' + config.fmeConfig.uploadDataToken
      
      await CallRestEndpoint(processUrl, "POST", false, "", { headers: {
        "Content-Type": 'application/x-www-form-urlencoded'
      }
      });
      return true;
    }
    catch (error: any){
      console.log(error);
      return false;  
    }
  }


  const submitData = async (dataItem:any) => {
    dispatch(startLoader());
    const upFile = dataItem.files[0];
    let isValid = true;

    let gdbName = "";
    let rawFile = upFile.getRawFile();

    await loadAsync(rawFile) 
      .then((zip) => {
          zip.forEach((relativePath, zipEntry) => {
            gdbName = relativePath.split("\/")[0];
            return;
          });
          
      }, (e) => {
        console.log(e);
      });

    // if the file isn't a valid GDB then don't allow to upload
    if (gdbName === "" || !gdbName.toLowerCase().endsWith(".gdb")){
      isValid = false;

      setInvalidFile(true);
      
      dispatch(addErrorNotification("An error occured uploading the file"));
    }
    else {      
      setInvalidFile(false);

      if (isValid) isValid = await deleteFiles(countyName)
      if (isValid) isValid = (await uploadFileToServer(countyName, rawFile));
      if (isValid) isValid = (await unzipFile(countyName, upFile.name));
      if (isValid) isValid = (await processFile(countyName, gdbName));
      
      if (isValid){
        dataItem.files = [];
        dispatch(addSuccessNotification("Successfully added to update"));
      }
      else{
        dispatch(addErrorNotification("An error occured uploading the file"));
      }
    }
    dispatch(endLoader());
  }

  const submitSchema = async (dataItem:any) => {
    dispatch(startLoader());

    try {
      const upFile = dataItem.files[0];
      
      const uploadUrl = getUrl(config!.fmeConfig.uploadSchemaMethod, upFile.name, config!.fmeConfig.uploadDataToken);
      
      const formData = new FormData();
      console.log(uploadUrl);

      formData.append(
        "files", upFile.getRawFile()
      );

      await CallRestEndpoint(uploadUrl, "POST", false, formData, {
        onUploadProgress: diplayUploadProgress
      });
      
      dataItem.files = [];
      dispatch(addSuccessNotification("Successfully added to update"));
    } catch (error:any) {
      console.log(error);
      dispatch(addErrorNotification("An error occured uploading the file"));
    }
    dispatch(endProgress());
    dispatch(endLoader());
  }

  const diplayUploadProgress = (progressEvent: any) => {
    dispatch(updateProgress(Math.round((progressEvent.progress + Number.EPSILON) * 1000) / 10));
    //console.log(progressEvent);
  }

  const handleSubmit = async (dataItem:any) => {
    if (dataItem.files.length > 0 
      && dataItem.files[0].validationErrors !== undefined 
      && dataItem.files[0].validationErrors.length > 0){
      dispatch(addErrorNotification("Invalid extension type."));
    }
    else{
      if (checked === "data"){
        submitData(dataItem);
      }
      else {
        submitSchema(dataItem);
      }
    }
  }


  const getUrl = (method: string, fileName: string, token: string) => {
    const fmeConfig = config!.fmeConfig;
    
    let url = fmeConfig.baseUrl + method; 

    let email = userDetail?.email??"";
    
    url = url.replaceAll("{fileName}", fileName);
    url = url.replaceAll("{token}", token);
    url = url.replaceAll("{county}", countyName??"");
    url = url.replaceAll("{requesterEmail}", email);
    return url;
  }

  return (         
    <div className="upload-page">
      <h1>Upload Data</h1>
      <div className="upload-flex">
        <div className="upload-form">
        <Form
          onSubmit={handleSubmit}
          render={(formRenderProps: FormRenderProps) => (
            <form onSubmit={formRenderProps.onSubmit} className={"k-form"}>
          <fieldset className={"k-form-fieldset one-child"}>
          <legend className={"k-form-legend"}>Upload Data</legend>
            <div>
              <RadioGroup
                data={data}
                value={checked}
                layout="horizontal"
                onChange={handleChange}
              />
            </div>
            <label className="input-notice">Allowed file types:&nbsp;
              {extensions.join(", ")}    
            </label>
            <div>
            <Field name={"files"} component={UploadInput} 
              restrictions={{
                allowedExtensions: extensions,
              }}/>
            {/* <Upload      
              autoUpload={false}      
              batch={false}
              multiple={false}
              restrictions={{
                allowedExtensions: extensions,
              }}
              files={files}
              onAdd={onAdd}
              onRemove={onRemove}
              onProgress={onProgress}
              onStatusChange={onStatusChange}
              showActionButtons={false}
              withCredentials={false}
              //saveUrl={"https://gisdev.ncrcolorado.org:8443/fmerest/v2/resources/FME_SHAREDRESOURCE_DATA/filesys/NCR/Boulder?createDirectories=true&detail=low&overwrite=true&token=7ecb1d3bd1b6f78e0f92c8540ecbf7deeb3b0206"}
              //saveUrl={"https://gisdev.ncrcolorado.org:8443/fmejobsubmitter/NCR/Upload_Boulder.fmw?token=7ecb1d3bd1b6f78e0f92c8540ecbf7deeb3b0206&opt_requesteremail=andrew.gewe@locana.co&opt_servicemode=async&opt_extractarchive=true&SourceDataset_GENERIC=Boulder_NCR_Upload.gdb.zip"}
            /> */}
            </div>
            <div> 
              <button
                type={"submit"}
                className="k-button"
                disabled={!formRenderProps.allowSubmit}
              >
                Submit
              </button>
            </div>
            </fieldset>
            </form>)} />
        </div>
        <div className="upload-options">
          <LinkListComponent {...uploadLink} />
        </div>
      </div>
  </div>
  );
}
