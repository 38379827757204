import React from "react";

// thunk imports
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { ILinkList, ICounty, ILookups } from '../../app/interfaces/commonInterfaces';
import {addSuccessNotification, addErrorNotification} from "../../app/notification/notificationSlice";

import {LinkListComponent}  from "../commonControls/linkList/LinkListComponent";
import {CallRestEndpoint} from "../../app/restHelper"
import { Input, RadioButton, RadioButtonChangeEvent } from "@progress/kendo-react-inputs";
import {
} from "@progress/kendo-react-inputs";
import { DropDownList,
  MultiSelect,
  MultiSelectChangeEvent } from "@progress/kendo-react-dropdowns";
import { endLoader, startLoader } from "../../app/navigation/navigationSlice";

interface IDownloadItem {
  downloadType: "extent" | "city" | "county";
  //city?: string;
  county: string;
  // lowerLeftX?: string;
  // lowerLeftY?: string;
  // upperRightX?: string;
  // upperRightY?: string;
  layer: ILookups[];
  coordinateSystem :ILookups;
  dataSchema: string;
}

export function DownloadComponent() {  
  const dispatch = useAppDispatch();
  const config = useAppSelector(state => state.config.config);
  
  const userDetail = useAppSelector(state => state.authInfo.userLoginInfo!.userDetail);
  let userCounty = userDetail?.county?.countyName??"";
  if (userCounty.toLowerCase() === "other"){
    userCounty = "";
  }

  const layers = config!.lookups.layers;
  const coordinateSystems = config!.lookups.coordinateSystems;
  
  const [downloadItem, setDownloadItem] = React.useState<IDownloadItem>({
    downloadType: "county",
    //city:"",
    // lowerLeftX: "",
    // lowerLeftY: "",
    // upperRightX: "",
    // upperRightY: "",
    county: userCounty,
    layer: [],
    coordinateSystem: coordinateSystems[0],
    dataSchema: "NCR",
  });

  //const cities = config.lookups.cities;
  const counties = config!.lookups.counties.map(c=> c.countyName).filter(c=> c !== "Other");
  let dataSchemas = ["NCR", ...counties];

  const handleInputChange = (event:any) => {
    const target = event.target;
    
    let name = target.element.name;
    let value = target.element.value;

    if (name === undefined){
      value = target.value;
      name = target.name;
    }

    setDownloadItem({...downloadItem, [name]: value});
  }

  const getUrl = () => {
    if (downloadItem.layer.length === 0){
      console.log("I need a layer selected");
    }
    console.log(downloadItem);
    const fmeConfig = config!.fmeConfig;
    // this is the url right now
    //https://gisdev.ncrcolorado.org:8443/fmedatadownload/NCR/Download_{schema}.fmw?
    //token={token}&opt_servicemode=async&selectedThemes={layers}&countyName={county}
    //&opt_requesteremail={requesterEmail}
    let url = fmeConfig.baseUrl + fmeConfig.downloadMethod; 

    let email = userDetail?.email??"";
    url = url.replaceAll("{schema}", downloadItem.dataSchema??"");
    url = url.replaceAll("{token}", fmeConfig.downloadToken);
    url = url.replaceAll("{layers}", downloadItem.layer?.map(l=> l.id).join(" ")??"");
    url = url.replaceAll("{county}", downloadItem.county??"");
    url = url.replaceAll("{requesterEmail}", email);
    return url;
  }

  const handleSubmit = (dataItem: { [name: string]: any }) => {

    const url = getUrl();
    console.log(url);
    
    dispatch(startLoader());
    const result = CallRestEndpoint(url, "POST", false, "", { headers: {
      "Content-Type": 'application/x-www-form-urlencoded'
    }
    })
      .then(r => dispatch(addSuccessNotification("The download has been successfully submitted")))
      .catch(e=> {
        console.log(e);
        dispatch(addErrorNotification("The download had an error submitting"));
      })
      .finally(() => dispatch(endLoader()));
    //dispatch(loginUserAsync(login));  
  }

  const selectAllLayers = () => {
    console.log("all layers");
    
    setDownloadItem({...downloadItem, ["layer"]: layers});    
  }
  // create the link info to pass to the component
  const downloadLink: ILinkList = {
    title: "Options",
    links: config!.downloadLinks
  }

  return ( 
    <div className="download-page">
      <h1>Download Data</h1>
      <div className="download-flex">
        <fieldset className={"k-form-fieldset download-form"}>
          <legend className={"k-form-legend"}>Download Data</legend>
          
          {/* <fieldset className={"k-form-fieldset"}>
            <legend className={"k-form-legend"}>
            <RadioButton
              name="downloadType"
              value="extent"
              label="Extent"
              checked={downloadItem.downloadType === "extent"}
              onChange={handleInputChange}
            />
            </legend>
            <div className="mb-3">
              <label>Lower Left X-Value</label>
              <Input validityStyles={false} name="lowerLeftX" type="text" style={{ width: "100%" }}
                onChange={handleInputChange} disabled={downloadItem.downloadType !== "extent"} />
            </div>
            <div className="mb-3">
              <label>Lower Left Y-Value</label>
              <Input validityStyles={false} name="lowerLeftY" type="text" style={{ width: "100%" }}
                onChange={handleInputChange} disabled={downloadItem.downloadType !== "extent"} />
            </div>
            <div className="mb-3">
              <label>Upper Right X-Value</label>
              <Input validityStyles={false} name="upperRightX" type="text" style={{ width: "100%" }}
                onChange={handleInputChange} disabled={downloadItem.downloadType !== "extent"} />
            </div>
            <div className="mb-3">
              <label>Upper Right Y-Value</label>
              <Input validityStyles={false} name="upperRightY" type="text" style={{ width: "100%" }}
                onChange={handleInputChange} disabled={downloadItem.downloadType !== "extent"} />
            </div>
          </fieldset>     */}
      
          {/* <fieldset className={"k-form-fieldset"}>
            <legend className={"k-form-legend"}>
            <RadioButton
              name="downloadType"
              value="city"
              label="City"
              checked={downloadItem.downloadType === "city"}
              onChange={handleInputChange}
            />
            </legend>
            <div>
              <label>City</label>
                  <DropDownList style={{ width: "100%" }} name="city"
                    textField="name" dataItemKey="id" data={cities} onChange={handleInputChange}
                    disabled={downloadItem.downloadType !== "city"} />
            </div>
          </fieldset> */}

          <fieldset className={"k-form-fieldset"}>
          <div className="k-radio-list k-list-horizontal faker">
            <RadioButton
              name="downloadType"
              value="county"
              label="County"
              checked={downloadItem.downloadType === "county"}
              onChange={handleInputChange}
            />
            </div>
            <div className="mb-3">
              <label>County</label>
                  <DropDownList style={{ width: "100%" }} name="county"
                    data={counties} onChange={handleInputChange}
                    value={downloadItem.county}
                    disabled={downloadItem.downloadType !== "county"} />
            </div>
          </fieldset>
          
          <fieldset className={"k-form-fieldset"}>
            <div className="mb-3">
              <label>Select Layer(s)</label>
              <MultiSelect textField="name" dataItemKey="id" 
              name="layer" data={layers} value={downloadItem.layer}  style={{ width: "100%", marginBottom: "8px" }} onChange={handleInputChange} />
              <button className="k-button auxBtn auxBtn-function" onClick={selectAllLayers}>Select All Layers</button>
            </div>
            <div className="mb-3">
              <label>Choose Coordinate System</label>
                  <DropDownList style={{ width: "100%" }} name="coordinateSystem"
                    value={downloadItem.coordinateSystem}
                    textField="name" dataItemKey="id" data={coordinateSystems} onChange={handleInputChange} />
            </div>
            <div className="mb-3">
              <label>Data Schema</label>
              <DropDownList style={{ width: "100%" }} name="dataSchema" 
                //value={downloadItem.dataSchema}
                defaultValue={downloadItem.dataSchema}
                data={dataSchemas}  onChange={handleInputChange} />
            </div>
          </fieldset>


          <div className="k-form-buttons">
            <button type={"button"} onClick={handleSubmit}
              className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-base" 
              >Submit</button>
              <button type={"button"} onClick={handleSubmit}
                className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-base cancel-btn" 
                >Cancel</button>
          </div>
        </fieldset>
        <div className="download-options">
          <LinkListComponent {...downloadLink} />
        </div>
      </div>

    </div>          

  );
}
