import axios, {AxiosResponse, AxiosError} from 'axios';
import { IConfig } from './interfaces/commonInterfaces';
//import {config} from '../config';
import {store} from './store'

type CallType = "GET" | "POST" | "PUT" | "DELETE";
interface IEndpointMethod {
    methodName: string;
    callType: CallType;
    includeToken: boolean
}  

interface IEndpointMethods {
    GetAllUsers:IEndpointMethod;
    AuthenticateUser:IEndpointMethod;
    GetRoles:IEndpointMethod;
    GetOrganizations:IEndpointMethod;
    GetCounties:IEndpointMethod;
    SaveUser: IEndpointMethod;
    DeleteUser: IEndpointMethod;
    UpdatePassword: IEndpointMethod;
    ResetPassword: IEndpointMethod;
}  

export const EndPointMethods: IEndpointMethods = {
    GetAllUsers:{ 
        methodName: "users/all",
        callType: "GET",
        includeToken: true
    },
    AuthenticateUser:{ 
        methodName: "users/authenticate",
        callType: "POST",
        includeToken: false
    },
    GetRoles:{ 
        methodName: "users/roles",
        callType: "GET",
        includeToken: true
    },
    GetOrganizations:{ 
        methodName: "users/organizations",
        callType: "GET",
        includeToken: true
    },
    GetCounties:{ 
        methodName: "users/counties",
        callType: "GET",
        includeToken: true
    },
    SaveUser:{ 
        methodName: "users/save",
        callType: "PUT",
        includeToken: true
    },
    DeleteUser:{ 
        methodName: "users/delete",
        callType: "DELETE",
        includeToken: true
    },
    UpdatePassword:{ 
        methodName: "users/updatePassword",
        callType: "POST",
        includeToken: true
    },
    ResetPassword:{ 
        methodName: "users/resetPassword",
        callType: "POST",
        includeToken: false
    }
}
  

export async function CallRestEndpointMethod<t>(config: IConfig, method: IEndpointMethod, data: any = undefined) { 
    
    let url = config.baseApiUrl + method.methodName;
    try {
        let resp = await CallRestEndpoint<any>(url, method.callType, method.includeToken, data);    
        let obj = (resp as AxiosResponse<t, any>);
        return obj;    
    } catch (error) {
        let obj = ((error as AxiosError).response as AxiosResponse<t, any>);
        return obj;
    }     
}

export async function CallRestEndpoint<t>( url: string, callType: CallType, includeToken: boolean, data: any = undefined, options: any = undefined) { 
    let calloptions = {};    
    if (includeToken){
        let token = store.getState().authInfo?.userLoginInfo?.token;
    
        calloptions = {    
            headers: {
                Authorization: 'Bearer ' + token //the token is a variable which holds the token
            }
        };
    }

    calloptions = {...calloptions, ...options};
    
    switch (callType) {
        case "PUT":
            return axios.put<t>(url, data, calloptions);
        case "DELETE":
            if (data !== undefined){
                url += data;
            }
            return axios.delete<t>(url, calloptions);
        case "POST":       
            return axios.post<t>(url, data, calloptions);
        default:
            if (data !== undefined){
                url += data;
            }
            return axios.get<t>(url, calloptions);               
    }
    
    //return CallRestEndpointMethod<t>({methodName: methodName, callType: callType, includeToken: includeToken }, data );
}
