export function ContactUsComponent() {  
  return (         
    <div className="host-page">
      <h1>Contact Us</h1>
      <div className="contact-info">
        <p className="person">Scott Kellar, <span className="person-title">Homeland Security Coordinator</span></p>
        <p><span className="bold">Colorado North Central All-Hazards Region</span><br />
          12469 E. Caley Avenue<br />
          Suite 114<br />
          Centennial, CO 80111</p>
        <p>Office: <span className="bold">(303) 768-8732</span><br />
          Fax: <span className="bold">(303) 768-8729</span><br />
          <a href="mailto:skellar@arapahoegov.com">skellar@arapahoegov.com</a>
        </p>
      </div>
  </div>
  );
}
