import { useEffect } from "react";

import { useAppSelector, useAppDispatch } from '../../app/hooks';
import {INotification} from '../../app/interfaces/commonInterfaces';

import {
  Notification,
  NotificationGroup,
  NotificationEvent
} from "@progress/kendo-react-notification";
import { Fade } from "@progress/kendo-react-animation";

import {closeNotificationDialog} from '../../app/notification/notificationSlice'

// the tab conponent
export function NotificationComponent() {
  const notifications = useAppSelector(state => state.notification.notifications);
  
  return (
    <NotificationGroup
        style={{
          right: 44,
          bottom: 44,
          padding: 13,
          alignItems: "flex-start",
          flexWrap: "wrap-reverse",
          zIndex: 800,
        }}
      >
          {notifications.map((notification, index: number) => {
          return(<NotificationItem {...notification} key={"notification_" + index} />)
          })}
        
    </NotificationGroup>
  )
}



// create the link list item
function NotificationItem(props: INotification) {  
  const config = useAppSelector(state => state.config.config);

  const dispatch = useAppDispatch();
  const { id, type, message } = props;
  
  // fires when the app bar items are clicked
  // will call set navigation by name
  const handleClose = (e: NotificationEvent) => {
    dispatch(closeNotificationDialog(props));
  }

  
  useEffect(() => {
    const timeoutID = setTimeout(() => {    
      dispatch(closeNotificationDialog(props));
      clearTimeout(timeoutID);
    }, config!.notificationAutoClose);
  }, []);

  return (    
  <Fade>
    <Notification
      type={{ style: type, icon: false }}
      style={{
        padding: 13,
      }}
      closable={true}
      onClose={handleClose}         
    >
      <span>{message}</span>
    </Notification>
  
  </Fade>
  );
}