import {IUserDetail, IRole, ICounty, ILogin, IBaseResult, IConfig} from '../interfaces/commonInterfaces';
import {CallRestEndpointMethod, EndPointMethods} from '../restHelper'

// getting the users
export async function getUsers(config: IConfig) {
  return (await CallRestEndpointMethod<IUserDetail[]>(config, EndPointMethods.GetAllUsers)).data;
}

export async function getDistinctOrgs(config: IConfig) {
  // const orgWithDups = users.filter(u=> u !== undefined).map(u => u.organization!);
  // return orgWithDups.filter((n, i) => n && orgWithDups.indexOf(n) === i);
  
  return (await CallRestEndpointMethod<string[]>(config, EndPointMethods.GetOrganizations)).data;
}

export async function getCounties(config: IConfig) {
  // const orgWithDups = users.filter(u=> u !== undefined).map(u => u.organization!);
  // return orgWithDups.filter((n, i) => n && orgWithDups.indexOf(n) === i);
  
  return (await CallRestEndpointMethod<ICounty[]>(config, EndPointMethods.GetCounties)).data;
}

export async function getAllRoles(config: IConfig) {
  return (await CallRestEndpointMethod<IRole[]>(config, EndPointMethods.GetRoles)).data;
  // return [
  //   {
  //     roleName: "Administrator",
  //     id: 1,
  //     group: "Admin"
  //   },
  //   {
  //     roleName: "Level 1 - Core Partners",
  //     id: 2,
  //     group: "Level"
  //   },
  //   {
  //     roleName: "Level 2 - HLS Planning Partners",
  //     id: 3,
  //     group: "Level"
  //   },
  //   {
  //     roleName: "Level 3 - Regional Partners",
  //     id: 4,
  //     group: "Level"
  //   },
  //   {
  //     roleName: "Level 4 - General Use",
  //     id: 5,
  //     group: "Level"
  //   },
  //   {
  //     roleName: "Group A - View Data",
  //     id: 6,
  //     group: "Group"
  //   },
  //   {
  //     roleName: "Group B - Upload Data",
  //     id: 7,
  //     group: "Group"
  //   },
  //   {
  //     roleName: "Group C - Download Data",
  //     id: 8,
  //     group: "Group"
  //   }
  // ]
}


export async function saveUser(config: IConfig, userDetail: IUserDetail) {
  return (await CallRestEndpointMethod<IBaseResult>(config, EndPointMethods.SaveUser, userDetail)).data;
}

export async function deleteUser(config: IConfig, userDetail: IUserDetail) {
  var queryString = "?id="+ userDetail.id;
  return (await CallRestEndpointMethod<IBaseResult>(config, EndPointMethods.DeleteUser, queryString)).data;
}

export async function updatePassword(config: IConfig, login: ILogin) {
  return (await CallRestEndpointMethod<IBaseResult>(config, EndPointMethods.UpdatePassword, login)).data;
}

export async function resetPassword(config: IConfig, login: ILogin) {
  return (await CallRestEndpointMethod<IBaseResult>(config, EndPointMethods.ResetPassword, login)).data;
}

// export function getUserByLogin(userName: string) {
//   return getUsers().then(users => {
//     return users.find(u=> u.userName?.toLowerCase() === userName);
//   });
// }