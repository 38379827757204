import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { stat } from 'fs';
import {INotification, INotificationState} from '../interfaces/commonInterfaces';


const initialState: INotificationState = {
  notifications: [],
  nextId: 1
};

export const notificationSlice = createSlice({
  name: 'mynavigation',
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    addSuccessNotification: (state, action: PayloadAction<string>) => {
      console.log("success notification", action.payload);
      state.notifications.push({
       id: state.nextId,
       type: "success",
       message: action.payload});
      state.nextId += 1;      
    },
    addErrorNotification: (state, action: PayloadAction<string>) => {
      console.log("error notification", action.payload);
      state.notifications.push({
       id: state.nextId,
       type: "error",
       message: action.payload});
      state.nextId += 1;      
    },
    closeNotificationDialog: (state, action: PayloadAction<INotification>) => {
      state.notifications = state.notifications.filter(n=> n.id !== action.payload.id);
    }
  }
});

export const { addSuccessNotification, addErrorNotification, closeNotificationDialog } = notificationSlice.actions;

export default notificationSlice.reducer;
