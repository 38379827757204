// thunk imports
import { ILinkList } from '../../app/interfaces/commonInterfaces';
import {LinkListComponent}  from "../commonControls/linkList/LinkListComponent";
import { useAppSelector } from '../../app/hooks';

export function WelcomeComponent() {

  const config = useAppSelector(state => state.config.config);

  // create the link info to pass to the component
  const welcomeLink: ILinkList = {
    title: "GIS Information",
    links: config!.welcomeLinks
  }

  return (         
  <div className='welcome-page'>
    <h1>Welcome to the North Central Region GIS Data Repository</h1>
    <div className='welcome-body'>
      <div className="welcome-copy">
        <p>North Central Region GIS Data Repository acts as a central repository for geospatial data that cover a wide variety of subjects, and that are made available from multiple data providers. Frequently-requested county data are managed by the NCR and the data is made available to NCR site members for download.</p>
        <p>North Central Region GIS Data Repository allows you to visually review the data via a web browser so that you can see if what is available matches your data needs.</p>
        </div>
      <div className="welcome-link">
        { <LinkListComponent {...welcomeLink} /> }
      </div>
    </div>
  </div>
  );
}
