import React, { useRef, useEffect } from "react";

// thunk imports
import { useAppSelector, useAppDispatch } from '../../app/hooks';
import {setMapExtent, startLoader, endLoader} from '../../app/navigation/navigationSlice';


// ESRI Imports
import WebMap from '@arcgis/core/WebMap';
import MapView from '@arcgis/core/views/MapView';
import PortalItem from "@arcgis/core/portal/PortalItem";
import Portal from "@arcgis/core/portal/Portal";
import * as reactiveUtils from "@arcgis/core/core/reactiveUtils";
import esriConfig from "@arcgis/core/config";
import * as watchUtils from "@arcgis/core/core/watchUtils";


// ESRI Widgets
import Expand from "@arcgis/core/widgets/Expand";
import BasemapGallery from "@arcgis/core/widgets/BasemapGallery"
import Legend from "@arcgis/core/widgets/Legend";
import Measurement from "@arcgis/core/widgets/Measurement";
import LayerList from "@arcgis/core/widgets/LayerList";
import Search from "@arcgis/core/widgets/Search";
import IdentityManager from "@arcgis/core/identity/IdentityManager";

// import the style
import styles from './Map.module.css';

export function MapComponent() {  
  const config = useAppSelector(state => state.config.config);
  const token = useAppSelector(state => state.authInfo.userLoginInfo?.mapToken!);
  const expires = useAppSelector(state => state.authInfo.userLoginInfo?.expires!);
  const currentExtent = useAppSelector(state => state.navigation.currentMapExtent);
  
  const dispatch = useAppDispatch();

  const mapDiv = useRef(null);
  const view = useRef<MapView>();

  const isMapLoading = useRef<boolean>(false);

  // this will be called each time the component is created
  useEffect(() => {
    if (isMapLoading.current) {
      return;
    }

    // esriConfig.portalUrl = config.mapConfig?.portalUrl??"";
    // if (esriConfig.request.trustedServers == undefined) esriConfig.request.trustedServers = [];
    // esriConfig.request.trustedServers.push(esriConfig.portalUrl);

    // only load the map if the config is loaded and the map not loaded
    if (mapDiv.current) {                
      isMapLoading.current = true;
    //   isMapLoading.current = true;  
      const mapConfig = config!.mapConfig!;

      dispatch(startLoader());

      // register with the map
      if (token!== undefined){
        IdentityManager.registerToken({server: config!.mapConfig!.portalUrl! + "sharing/rest",
          token: token
        });
      }
      
      // get the portal item
      var portalItem = new PortalItem({
        id: mapConfig.webmapPortalId   
      });

      // if has a portal url, set it to the item
      if (mapConfig.portalUrl && mapConfig.portalUrl !== ""){
        portalItem.portal = new Portal({
          url: mapConfig.portalUrl
        });
      }

      // create the web map
      const webmap = new WebMap({
        portalItem: portalItem
      });

      //create the map view
      view.current = new MapView({
        container: mapDiv.current!,
        map: webmap,
        extent: currentExtent
      });

      // load after map created
      webmap.when(() => {
        if (webmap.allLayers.at(0).loadStatus !== "loaded"){   
          //console.log();
          var loadingWatch = reactiveUtils.watch(() => webmap.allLayers.at(0).loadStatus,
          (status) => {
            if (status === "loaded"){
              //console.log(status);            
              dispatch(endLoader());
              loadingWatch.remove();
            }          
          });
        }
        else {                    
          dispatch(endLoader());
        }
        // view.current!.allLayerViews.forEach(layerview => {
        //   reactiveUtils.watch(() => layerview.updating,
        //   (updating) => {
        //     console.log(layerview.layer.id, updating);
        //   });
        // });
        //reactiveUtils.when(webmap, "")
        //webmap.allLayers.forEach(l=> l.);
        // create conrols in the map
        mapLoadedEvent();
      });


      reactiveUtils.watch(
        () => getView()?.extent,
        (extent) => {
          dispatch(setMapExtent(extent));
        });
    }
    
  }, [token, isMapLoading, view]);

  // get the map view
  const getView = () : MapView => {
    return view.current!;
  }
  
  // load all the widgets
  const mapLoadedEvent = () => { 
    // load the base map gallery
    getView().ui.add(new Expand({      
      expandIconClass: "esri-icon-basemap", 
      collapseIconClass : "esri-icon-basemap",
      view: getView(),
      content: new BasemapGallery({
        view: getView(),
      }),
      expanded: false
    }), "top-right");

    // load the legend
    getView().ui.add(new Expand({      
      expandIconClass: "esri-icon-legend",
      collapseIconClass : "esri-icon-legend",
      view: getView(),
      content: new Legend({
        view: getView()
      }),
      expanded: false
    }), "top-right");

    // load the layer list
    getView().ui.add(new Expand({      
      expandIconClass: "esri-icon-layer-list", 
      collapseIconClass : "esri-icon-layer-list",
      view: getView(),
      content: new LayerList({
        view: getView()
      }),
      expanded: false
    }), "top-right");

    // load the Measurement
    getView().ui.add(new Expand({      
      expandIconClass: "esri-icon-measure", 
      collapseIconClass : "esri-icon-measure",
      view: getView(),
      content: new Measurement({
        activeTool: "distance",
        view: getView()
      }),
      expanded: false
    }), "top-right");

    // load the Measurement
    getView().ui.add(new Expand({      
      expandIconClass: "esri-icon-search", 
      collapseIconClass : "esri-icon-search",
      view: getView(),
      content: new Search({
        view: getView()
      }),
      expanded: false
    }), "top-left");

  }
  
  return <div className={styles.mapDiv + " leMap"} ref={mapDiv}></div>;
}
