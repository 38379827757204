import { configureStore, ThunkAction, Action } from '@reduxjs/toolkit';
import authenticationReducer from './authentication/authenticationSlice';
import navigationReducer from './navigation/navigationSlice';
import userManagementSlice from './userManagement/userManagementSlice';
import notificationSlice from './notification/notificationSlice';
import configSlice from './config/configSlice';

export const store = configureStore({
  reducer: {
    authInfo: authenticationReducer,
    navigation: navigationReducer,
    userManagement: userManagementSlice,
    notification: notificationSlice,
    config: configSlice
  },
  middleware: (getDefaultMiddleware) => getDefaultMiddleware({
    serializableCheck: false
  })
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
