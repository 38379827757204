import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {loginUserAsync} from '../authentication/authenticationSlice'
import {INavigation, IDialogState} from '../interfaces/commonInterfaces';
import Extent from "@arcgis/core/geometry/Extent";


const initialState: INavigation = {
  name: "",
  tabId: 0,
  dialogState: {
    name: "",
    isOpen: false
  },
  loader: {
    message: "",
    isLoading: false,
  },
  progress: {
    message: "",
    isProgress: false,
    progressComplete: 0
  },
  currentMapExtent: undefined
};

export const navigationSlice = createSlice({
  name: 'mynavigation',
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    setNavigationByTabId: (state, action: PayloadAction<number>) => {
      state.tabId = action.payload;
      state.name = "";
    },
    setNavigationByName: (state, action: PayloadAction<string>) => {
      state.name = action.payload;      
      state.tabId = -1;
    },
    setNavigationByIFrame: (state, action: PayloadAction<string>) => {
      state.name = "IFRAME";      
      state.tabId = -1;
      state.iframeUrl = action.payload;
    },
    openNavigationDialog: (state, action: PayloadAction<IDialogState>) => {
      state.dialogState.name = action.payload.name;
      state.dialogState.data = action.payload.data;
      state.dialogState.isOpen = true;
    },
    closeNavigationDialog: (state) => {
      state.dialogState.name = "";
      state.dialogState.isOpen = false;
    },
    startLoader: (state, action: PayloadAction<string|undefined>) => {
      state.loader.isLoading = true;
      state.loader.message = action.payload;
      state.progress.isProgress = false;
    },
    endLoader: (state) => {
      state.loader.isLoading = false;
    },
    startProgress: (state, action: PayloadAction<string|undefined>) => {
      state.progress.isProgress = true;
      state.progress.message = action.payload;
      state.loader.isLoading = false;
      state.progress.progressComplete = 0;
    },
    updateProgress: (state, action: PayloadAction<number>) => {
      state.progress.progressComplete = action.payload;
    },
    endProgress: (state) => {
      state.progress.isProgress = false;
    },
    setMapExtent: (state, action: PayloadAction<Extent|undefined>) => {
      state.currentMapExtent = action.payload;
    }

  },
  extraReducers: (builder) => {
    builder
      .addCase(loginUserAsync.fulfilled, (state, action) => {
        state.tabId = 0;
      })      
    },
});

export const { setNavigationByTabId, setNavigationByName, setNavigationByIFrame, 
  openNavigationDialog, closeNavigationDialog, startLoader, endLoader, startProgress, 
  updateProgress, endProgress, setMapExtent } = navigationSlice.actions;

export default navigationSlice.reducer;
